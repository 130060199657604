.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.App-header {
  margin: 2rem 2rem;
}


.App-logo {
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin 1 0.2s ease-in-out;
  }
  .App-access {
    animation: App-Text 1 1.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: scale(0.9) translate(0px, 0px);
  }
  to {
    transform: scale(1) translate(0px, 0px);
  }
}

@keyframes App-Text {
  from {
    transform: scale(0.9) translate(0px, 50px);
    opacity: 0;
  }
  to {
    transform: scale(1) translate(0px, 0px);
    opacity: 1;
  }
}

.App-access {
  display: flex;
  flex-direction: row;
  margin: 1rem 15vw;
  align-items: center;
}

.App-home-image {
  width: 170px;
}
.App-home-title {
  margin: 3rem 5rem;
  color: #fff;
  font-family: 'Montserrat', sans-serif!important;
  font-weight: 300;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin-top: 10vh;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #ccc;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ccc transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.App-course-days {
  margin-top: 10vh;
}
.App-course-days-content {
  display: flex;
  flex-direction: row;
  margin: 2rem 0px;
}
.App-course-days-title {
  color:#fff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}
.App-course-days-subtitle {
  color:#fff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 1.2rem;
}

.App-course-day {
  border-radius: 5px;
  cursor: pointer;
  padding: 10px 30px;
  user-select: none;
  background: rgba(255,255,255,0.1);
  transition: all 0.3s ease-in-out;
}
.App-course-day:hover:not(.App-course-day-disabled) {
  background:#FEE31B;
}

.App-course-day span {
  font-size: 2rem;
  color: #FEE31B;
  margin-top: 10px;
  display: block;
}
.App-course-day:hover:not(.App-course-day-disabled) span{
  color:#1a1a1a;
}
.App-course-day ul {
  list-style: none;
  padding-inline-start: 0px;
}
.App-course-day ul li {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  color: rgb(212, 212, 212);
}
.App-course-day:hover:not(.App-course-day-disabled) ul li{
  color:#2e2e2e;
}
.App-course-day ul li:nth-child(1n+2) {
  margin-top: 10px;
}
.App-course-day ul li img{
  width: 35px;
  border-radius: 50%;
  margin-right: 10px;
}

.App-course-day-disabled {
  cursor: not-allowed!important;
  opacity: 0.5;
}
.App-course-day:nth-child(1n+2) {
  margin-left: 1rem;
}


.App-course-item {
  border-radius: 5px;
  cursor: pointer;
  padding: 10px 30px;
  user-select: none;
  background: rgba(255,255,255,0.1);
  transition: all 0.3s ease-in-out;
}
.App-course-item:hover:not(.App-course-day-disabled) {
  background:#FEE31B;
}

.App-course-item img{
  width: 150px;
  border-radius: 50%;
  margin-top: 10px;
}

.App-course-item:nth-child(1n+2) {
  margin-left: 1rem;
}

.App-course-item span {
  font-size: 2rem;
  color: #f1f1f1;
  margin-top: 10px;
  display: block;
  font-weight: 500;
}
.App-course-item:not(.App-course-day-disabled):hover span{
  color: #1a1a1a;
}
.App-course-item p {
  font-size: 1.2rem;
  color: #c3c3c3;
  margin-top: 10px;
  display: block;
  max-width: fit-content;
}
.App-course-item:not(.App-course-day-disabled):hover p{
  color: #363636;
}

.back-action-button {
  background: rgb(101 101 101 / 20%);
  padding: 10px 30px;
  color: #d6d6d6;
  border: none;
  border-radius: 4px;
  font-size: 19px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

}
.back-action-button:hover {
  background:#FEE31B;
  color: #1a1a1a;
}
.back-action-button:active, .back-action-button:focus {
  outline: none;
}

.App-course-watch {
  display: flex;
  flex-direction: column;
}

.App-course-watch-details-ad{
  background: rgba(255,255,255,0.1);
  padding: 15px 35px;
  border-radius: 4px;
  margin-bottom: 1rem;
  display: block;
  flex-direction: row;
  width: max-content;
  align-self: center;
  margin: 1rem 0px;
}
.App-course-watch-details-ad a{
  color: #FEE31B;
  transition: all 0.2s ease;
}
.App-course-watch-details-ad a:hover{
  opacity: 0.7;
}
.App-course-watch-details-ad a:visited{
  color: #FEE31B;
}
.App-course-watch-details{
  background: rgba(255,255,255,0.1);
  padding: 15px 35px;
  border-radius: 4px;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  width: max-content;
  align-self: center;
  margin-top: 1rem;
}
.App-course-watch-details div:nth-child(1) {
  align-self: center;
}
.App-course-watch-details div:nth-child(1) img{
  width: 60px;
  border-radius: 50%;
  vertical-align: middle;
}
.App-course-watch-details div:nth-child(2){
  margin-left:30px;
  display: block;
  text-align: left;
  justify-content: center;
}
.App-course-watch-details div:nth-child(2) h2{
  margin: 0;
}
.App-course-watch-details div:nth-child(2) p{
  margin: 7px 0 0 0;
}
.App-course-watch-details div:nth-child(3){
  margin-left: 4rem;
  align-self: center;
}
.App-course-watch-video {
  width: 80vw;
  max-width: 100%;
}
.App-course-watch-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.App-course-watch-header-logo{
  display: flex;
  align-items: center;
  margin-right: 15vh;
}

.nextPalestra {
  margin-bottom: 2rem;
}
.nextPalestra h1{
  font-weight: 500;
}

.justify-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.justify-center h2 {
  margin-bottom: 10px;
  color: #d6d6d6;
  font-weight: 300;
}
.emojiVote {
  background: transparent;
  border: none;
  font-size: 3rem;
  cursor: pointer;
  margin-right: 1rem;
  opacity: 0.5;
  transition: all 0.1s ease;
}

.emojiVote:hover {
  opacity: 1;
  transform: scale(1.2);
}
.emojiVote:active, .emojiVote:focus {
  outline:none;
}
.endMessage {
  font-size: 1.3rem;
  background: rgba(0,0,0,0.2);
  color: #fff;
  padding: 25px 30px;
  max-width: 60%;
  align-self: center;
  margin-top: 2rem;
}

@media(max-width: 890px) {
  .App-course-watch {
    display: table-caption;
  }
  .App-course-days {
    margin: 1rem;
  }
  .App-course-days-content {
    flex-direction: column;
  }
  .App-course-day:nth-child(1n+2) {
    margin-left: 0px;
    margin-top: 1rem;
  }
  .App-course-item:nth-child(1n+2) {
    margin-left: 0px;
    margin-top: 1rem;
  }
  .App-course-watch-header {
    flex-direction: column;
  }
  .App-course-watch-header-logo {
    justify-content: center;
    margin: 0px;
    margin-top: 1rem;
  }
  .App-course-watch-details {
    flex-direction: column;
    display: block;
    width: auto;
  }
  .App-course-watch-details div:nth-child(1) img{
    width: 20vw;
  }
  .App-course-watch-details div:nth-child(2){
    margin: 15px 0px;
    display: block;
  }
  .App-course-watch-details div:nth-child(3){
    margin-left: 0px;
    align-self: center;
  }
  .endMessage {
    max-width: 90%;
    margin-top: 0.5rem;

  }
}
.youtubeContainer {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  margin-bottom: 50px;
}

.youtubeContainer iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.footer {
  margin: 1rem 0rem;
}
.footer h6{
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  text-align: left;
}
.footer-grid {
  display: grid;
  background: #ffffffd2;
  border-radius: 8px;
  margin-top: 5px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  padding: 10px;
}
.footer-grid img {
  width: 174px;
}
@media(max-width: 1205px) {
  .footer-grid {
    display: block;
    border-radius: 8px;
    margin-top: 5px;
    
  }
  .footer-grid img {
    width: 120px;
    margin-left: 2rem;
  }
  .footer {
    margin: 1rem;
  }
}
@media(max-width: 1200px) and (min-width: 890px) {
.App{
  margin: 1rem 2rem 1rem 1rem;
}
}
@media(min-width: 1200px) {
.footer {
  margin-top: 3rem;
}
}
@media(max-width: 750px) {
  .footer {
    margin: 1rem;
  }
  .footer-grid {
    display: block;
    border-radius: 8px;
    margin-top: 5px;
    
  }
  .footer-grid img {
    width: 40%;
    margin: 0px 1rem;
  }
}